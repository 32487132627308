<template>
  <a-modal width="50%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-card  :bordered="false" title="商品信息"
             :headStyle="{'background-color':'#f3f3f3','height': '29px','border-bottom': '1px solid #DDD','margin-bottom':'0'}"
             :bodyStyle="{'background-color':'#fbfbfb'}">
      <table class="info-table">
        <tr>
          <td class="first">商品编号：</td>
          <td class="data">
            {{ this.goodsInfo.id}}
          </td>
          <td class="first">商品名称：</td>
          <td class="data">
            {{ this.goodsInfo.goodsName}}
          </td>
        </tr>
        <tr>
          <td class="first">售卖结束日期：</td>
          <td class="data">
            {{ this.goodsInfo.saleEndTime}}
          </td>
        </tr>
      </table>
    </a-card>
    <a-card  :bordered="false" title="订单信息"
             :headStyle="{'background-color':'#f3f3f3','height': '29px','border-bottom': '1px solid #DDD','margin-bottom':'0'}"
             :bodyStyle="{'background-color':'#fbfbfb'}">
      <table class="info-table">
        <tr>
          <td class="first">单数：</td>
          <td class="data">
            {{ this.form.orderIdList.length}}
          </td>
        </tr>
      </table>
    </a-card>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item :label="$t('销售订单.锁单时间')" prop="lockTime" >
        <a-date-picker style="width: 100%" v-model="form.lockTime"
                       :show-time="{defaultValue: moment('00:00:00', 'HH:mm:ss')}"
                       valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.修改')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-modal>
</template>

<script>
import {getGoods} from '@/api/goods/goods'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import moment from 'moment';
import {batchUpdateLockTime, listSell} from "@/api/order/sell";

export default {
  name: 'DeliveryForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      logisticsList: [],
      goodsInfo: {
        id:null,
      } ,
      orderCount:0,
      // 表单参数
      form: {
        orderIdList: [],
        lockTime:null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        lockTime: [
          { required: true, message: this.$t('销售订单.锁单时间')+this.$t('通用.文本.不能为空'), trigger: 'change' }
        ],
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    moment,
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        orderIdList:  [],
        lockTime:null,
      }
    },
    /** 修改按钮操作 */
    handleUpdateLockTime(id) {
      if (!id) {
        this.$message.success(
          "请先输入商品编号",
          3
        )
        return;
      }
      this.reset();
      this.formType = 2
      getGoods({"id":id}).then(response => {
        this.goodsInfo = response.data
        this.form.id= id
        this.open = true
        this.formTitle = this.$t('销售订单.锁单时间')
      })
      listSell({"goodsId":id,"orderStatusList":[2010,2015,2020]}).then(response => {
        this.form.orderIdList = response.data.map(e=>e.id)
      })
    },

    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (!this.form.orderIdList || this.form.orderIdList.length === 0) {
            this.$message.success(
              "无订单", 3
            )
            return;
          }
          this.submitLoading = true;
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            batchUpdateLockTime(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style  lang="less" scoped>
.info-table {
  tr {
    height: 50px;
  }
  td {
    display: table-cell;
    vertical-align: top;
    font-size: 16px;
  }
  .first {
    width: 120px;
  }
  .data {
    width: 280px;
  }
}
</style>
